import React, { useState, useEffect, useRef } from 'react';
import { SmallButton } from './Button';
import { CheckIcon, Wand2Icon, XCircleIcon } from 'lucide-react';
import { useMutation } from 'react-query';
import { smartEditHelpArticle } from '../../lib/helpArticlesService';
import ReactMarkdown from 'react-markdown';

const HelpArticleEditor = ({ initialArticleContent, setArticleContent, articleId }: { initialArticleContent: string, setArticleContent: (content: string) => void, articleId: string }) => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const [aiInput, setAiInput] = useState('');
  const [selectedText, setSelectedText] = useState('');
  const editorRef = useRef<HTMLDivElement | null>(null);
  const popupRef = useRef<HTMLDivElement | null>(null);
  const selectionRef = useRef<Range | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const [smartEditResponse, setSmartEditResponse] = useState<string | null>(null);

  const { isSuccess: isSmartEditSuccess, mutate: smartEditMutation, isLoading: isSmartEditLoading, reset: resetSmartEdit } = useMutation({
    mutationFn: (params: { id: string; markdownText: string; changeRequest: string }) =>
      smartEditHelpArticle(params.id, params.markdownText, params.changeRequest),
    onSuccess: (data) => {
      setSmartEditResponse(data.editedMarkdown);
    }
  });

  const POPUP_HEIGHT = 160;
  const MAX_POPUP_HEIGHT = 300;
  const POPUP_WIDTH = 350;

  useEffect(() => {
    const handleSelection = () => {
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        if (editorRef.current && editorRef.current.contains(range.commonAncestorContainer)) {
          selectionRef.current = range.cloneRange();
        }
      }
    };

    const handleMouseUp = (e: MouseEvent) => {
      const selection = window.getSelection();
      if (selection && selection.toString().trim().length > 0) {
        const range = selection.getRangeAt(0);
        if (editorRef.current && editorRef.current.contains(range.commonAncestorContainer)) {
          const rect = range.getBoundingClientRect();
          updatePopupPosition(rect);
          setAiInput('');
          setPopupVisible(true);
          setSelectedText(selection.toString());
          addUnderlineToSelection(selection);
        }
      }
    };

    const handleClick = (e: MouseEvent) => {
      if (!popupRef.current?.contains(e.target as Node)) {
        removeUnderlineFromSelection();
        setPopupVisible(false);
      }
    };

    const handleScroll = () => {
      if (selectionRef.current && popupVisible) {
        const rect = selectionRef.current.getBoundingClientRect();
        updatePopupPosition(rect);
      }
    };

    const updatePopupPosition = (rect: DOMRect) => {
      const editorRect = editorRef.current?.getBoundingClientRect();
      if (editorRect) {
        const top = rect.top - editorRect.top + editorRef.current!.scrollTop;
        let left = rect.left - editorRect.left + editorRef.current!.scrollLeft;

        // Check if there's enough space at the top
        const topPosition = top >= POPUP_HEIGHT ? top - POPUP_HEIGHT : top + rect.height;

        // Adjust left position if it would go off-screen
        const rightEdge = left + POPUP_WIDTH;
        if (rightEdge > editorRect.width) {
          left = Math.max(0, editorRect.width - POPUP_WIDTH);
        }

        setPopupPosition({ top: topPosition, left });
      }
    };

    const addUnderlineToSelection = (selection: Selection) => {
      try {
        const range = selection.getRangeAt(0);
        const fragment = range.cloneContents();
        const span = document.createElement('span');
        span.className = 'ai-selection bg-teal-500 bg-opacity-20';

        // Wrap the contents of the fragment with the span
        span.appendChild(fragment);

        // Clear the original selection and insert the wrapped content
        range.deleteContents();
        range.insertNode(span);

        // Update the selection to the new span
        selection.removeAllRanges();
        const newRange = document.createRange();
        newRange.selectNodeContents(span);
        selection.addRange(newRange);
      } catch (error) {
        console.error('Error adding underline to selection:', error);
      }
    };

    document.addEventListener('selectionchange', handleSelection);
    document.addEventListener('mousedown', handleClick);
    document.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('selectionchange', handleSelection);
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [popupVisible]);

  const removeUnderlineFromSelection = () => {
    const underlinedSpans = editorRef.current?.querySelectorAll('.ai-selection');
    underlinedSpans?.forEach(span => {
      const parent = span.parentNode;
      while (span.firstChild) {
        parent?.insertBefore(span.firstChild, span);
      }
      parent?.removeChild(span);
    });
  };

  const rejectAiSubmit = () => {
    setSmartEditResponse(null);
    resetSmartEdit();
  }

  const handleAiSubmit = async () => {
    const underlinedSpan = editorRef.current?.querySelector('.ai-selection');
    if (underlinedSpan) {
      underlinedSpan.textContent = smartEditResponse;
      removeUnderlineFromSelection();
    }
    setPopupVisible(false);
    setAiInput('');
    setArticleContent(editorRef.current?.textContent || '');
    setSmartEditResponse(null);
    resetSmartEdit();
  };

  const runSmartEdit = () => {
    const underlinedSpan = editorRef.current?.querySelector('.ai-selection');
    if (underlinedSpan) {
      const selection = underlinedSpan.textContent;
      if (selection) {
        smartEditMutation({ id: articleId, markdownText: selection, changeRequest: aiInput });
      }
    }
  };

  return (
    <div className="w-full h-full border border-gray-200 rounded-lg relative">
      <div
        ref={editorRef}
        contentEditable
        suppressContentEditableWarning
        className="w-full h-full p-2 rounded outline-none whitespace-pre-wrap overflow-auto selection:bg-teal-500 selection:bg-opacity-20"
        style={{ lineHeight: '1.5' }}
        onInput={(e) => {
          const target = e.currentTarget as HTMLDivElement;
          setArticleContent(target.textContent || '');
        }}
        dangerouslySetInnerHTML={{ __html: initialArticleContent }}
      />

      {popupVisible && (
        <div
          ref={popupRef}
          style={{
            position: 'absolute',
            top: `${popupPosition.top}px`,
            left: `${popupPosition.left}px`,
            zIndex: 1000,
            width: `${POPUP_WIDTH}px`,
            minHeight: `${POPUP_HEIGHT}px`,
            maxHeight: `${MAX_POPUP_HEIGHT}px`,
          }}
          className="flex flex-col bg-white border border-gray-100 p-2 rounded-2xl shadow-lg"
          onMouseDown={(e) => e.stopPropagation()}
        >
          <div className="flex flex-1 flex-grow flex-col border-2 border-teal-500 rounded-xl overflow-hidden">
            {!isSmartEditSuccess ?
              <textarea
                ref={textareaRef}
                value={aiInput}
                onChange={(e) => setAiInput(e.target.value)}
                placeholder={`Enter AI instruction ...`}
                className="flex-1 p-2 rounded outline-none resize-none"
              />
              :
              <div className="flex-1 p-2 rounded outline-none resize-none overflow-scroll">
                <ReactMarkdown
                  components={{
                    h1: ({ node, ...props }) => <h1 className="text-xl font-semibold mt-2 mb-1" {...props} />,
                    h2: ({ node, ...props }) => <h2 className="text-lg font-semibold mt-1 mb-0" {...props} />,
                    p: ({ node, ...props }) => <p className="my-1" {...props} />,
                    ul: ({ node, ...props }) => <ul className="list-disc list-inside my-2" {...props} />,
                    ol: ({ node, ...props }) => <ol className="list-decimal list-inside my-2" {...props} />,
                    li: ({ node, ...props }) => <li className="ml-2 mb-2" {...props} />,
                    a: ({ node, ...props }) => <a className="text-green-800 hover:underline" {...props} />,
                  }}
                >
                  {smartEditResponse ? smartEditResponse : '(remove selection)'}
                </ReactMarkdown>
              </div>}
            <div className="flex flex-shrink-0 flex-row gap-2 p-2">
              <SmallButton
                icon={<XCircleIcon className="w-4 h-4" />}
                text="Cancel"
                onClick={() => setPopupVisible(false)}
                border={false}
                flat={true}
              />
              <div className="flex-1" />
              {!isSmartEditSuccess ? <SmallButton
                icon={<Wand2Icon className="w-4 h-4" />}
                text="Update"
                onClick={runSmartEdit}
                isLoading={isSmartEditLoading}
              />
                :
                <div className='flex flex-row gap-2'>
                  <SmallButton
                    icon={<XCircleIcon className="w-4 h-4" />}
                    text="Reject"
                    onClick={rejectAiSubmit}
                  />
                  <SmallButton
                    icon={<CheckIcon className="w-4 h-4" />}
                    text="Accept"
                    onClick={handleAiSubmit}
                  />
                </div>}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HelpArticleEditor;