import React from "react"
import { PageContainer } from "../components/containers/PageContainer"
import Header from "../components/ui/header/Header";
import { MainContainer } from "../components/containers/MainContainer"
import { Button, SmallButton } from "../components/ui/Button";
import { AppWindowIcon, CalendarIcon, CheckSquareIcon, ChromeIcon, ClockIcon, CreditCard, Download, DownloadIcon, FilmIcon, GlobeIcon, LampDeskIcon, Languages, RefreshCwIcon, SparkleIcon, StarIcon, UsersIcon, Wand2Icon, XCircleIcon, ZapIcon } from "lucide-react";
import { useQuery } from "react-query";
import { getUserProfile } from "../lib/profileService";

export const GetStarted: React.FC = () => {

  const { data: userProfile, isError: isUserProfileError, isLoading: isUserProfileLoading } = useQuery("userProfile", getUserProfile, {
    initialData: null,
  });

  const proFeatures = [
    // { icon: CalendarIcon, text: '50 articles/month' },
    { icon: Wand2Icon, text: 'Smart-Edit' },
    { icon: Languages, text: 'Translations' },
    // { icon: XCircleIcon, text: 'Cancel anytime' },
    { icon: ZapIcon, text: '1000 articles/month' },
    // { icon: ClockIcon, text: '2 minutes/article' },
  ];

  const faqs = [
    {
      question: 'What is HappyRecorder?',
      answer: 'HappyRecorder is a Chrome extension that allows you to record your screen and create articles from your recordings.'
    },
    {
      question: 'How does HappyRecorder work?',
      answer: 'HappyRecorder works by recording your screen and audio, and then creating an article from the recording.'
    },
    {
      question: 'What is HappyRecorder?',
      answer: 'HappyRecorder is a Chrome extension that allows you to record your screen and create articles from your recordings.'
    },
    {
      question: 'What is HappyRecorder?',
      answer: 'HappyRecorder is a Chrome extension that allows you to record your screen and create articles from your recordings.'
    },
    {
      question: 'What is HappyRecorder?',
      answer: 'HappyRecorder is a Chrome extension that allows you to record your screen and create articles from your recordings.'
    },
    {
      question: 'How does HappyRecorder work?',
      answer: 'HappyRecorder works by recording your screen and audio, and then creating an article from the recording.'
    },
    {
      question: 'What is HappyRecorder?',
      answer: 'HappyRecorder is a Chrome extension that allows you to record your screen and create articles from your recordings.'
    },
    {
      question: 'What is HappyRecorder?',
      answer: 'HappyRecorder is a Chrome extension that allows you to record your screen and create articles from your recordings.'
    },
  ];

  return (
    <PageContainer>
      <Header
        title="Get Started"
      />

      <MainContainer>
        <div className="flex flex-col 2xl:flex-row gap-4 flex-grow overflow-hidden">

          <div className="flex flex-grow flex-col gap-4 overflow-y-auto">

            <div className="flex flex-row gap-4 items-center">
              <div className="h-10 w-10 bg-zinc-300 rounded-full flex items-center justify-center">
                <span className="text-black text-lg font-bold">1</span>
              </div>
              <div className="flex flex-row flex-1 bg-white p-4 border rounded-2xl border-gray-200 items-center gap-4">
                <div className="flex flex-col flex-1 flex-grow">
                  <h3 className="text-lg font-semibold mb-1">Install Recorder</h3>
                  <span className="text-base opacity-60">Download and install the HappyRecorder.</span>
                </div>
                <div className="flex flex-row gap-2">
                  <SmallButton
                    text="Windows (soon)"
                    onClick={() => { }}
                    icon={<AppWindowIcon className="w-4 h-4" />}
                    disabled={true}
                  />
                  <SmallButton
                    text="Download for Chrome"
                    onClick={() => { }}
                    icon={<ChromeIcon className="w-4 h-4" />}
                    textColor="text-orange-600"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-row gap-4 items-center">
              <div className="h-10 w-10 bg-zinc-300 rounded-full flex items-center justify-center">
                <span className="text-black text-lg font-bold">2</span>
              </div>
              <div className="flex flex-row flex-1 bg-white p-4 border rounded-2xl border-gray-200 items-center gap-4">
                <div className="flex flex-col flex-1">
                  <div className="flex justify-between">
                    <h3 className="text-lg font-semibold mb-1">Record steps</h3>
                  </div>
                  <div className="text-base">
                    <ul className="mt-2 list-decimal list-inside">
                      <li className="opacity-60 mb-2">Start recording in the Chrome extension</li>
                      <li className="opacity-60 mb-2">Perform the task you want to document</li>
                      <li className="opacity-60 mb-2">Stop recording</li>
                      <li className="opacity-60 mb-2">Edit steps if needed</li>
                      <li className="opacity-60">Upload to save your guide</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-row gap-4 items-center">
              <div className="h-10 w-10 bg-zinc-300 rounded-full flex items-center justify-center">
                <span className="text-black text-lg font-bold">3</span>
              </div>
              <div className="flex flex-row flex-1 bg-white p-4 border rounded-2xl border-gray-200 items-center gap-4">
                <div className="flex flex-col flex-1">
                  <h3 className="text-lg font-semibold mb-1">Auto-Convert Recording to Article 🪄</h3>
                  <span className="flex flex-col text-base opacity-60">
                    {/* <span className="mb-4">Once uploaded, your recording will automatically turn into an article with a nice looking gif explaining the steps.</span>
        <span>Now you can easily edit, share and use your guide:</span>
        <ul className="list-disc list-inside">
          <li>Make changes by hand or using Smart-Edit by selecting the text you want to edit and telling what to change</li>
          <li>Download the article as markdown file and use it in your documentation</li>
        </ul> */}

                    <span className="mb-1">Your recording will auto-convert to an article with an explanatory gif.</span>

                    <ul className="list-disc list-inside">
                      <li>Edit manually or use Smart-Edit</li>
                      <li>Add automatic translations</li>
                      <li>Download article as markdown</li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>

            <div className="flex flex-row gap-4 items-center">
              <div className="h-10 w-10 bg-zinc-300 rounded-full flex items-center justify-center">
                <span className="text-black text-lg font-bold">4</span>
              </div>
              <div className="flex flex-row flex-1 bg-white p-4 border rounded-2xl border-gray-200 gap-4">
                <div className="flex flex-col flex-1">
                  <h3 className="text-lg font-semibold mb-1">Go Pro and unlock all features</h3>
                  <span className="flex flex-col text-base opacity-60">
                    <span>Generate up to 1,000 articles monthly, saving weeks of work. Perfect your content with smart editing and automatic translations.</span>
                  </span>

                  <div className="flex flex-row gap-2 mt-4 ">
                    {proFeatures.map((proFeature, index) => (
                      <div className="flex items-center gap-2 border p-2 rounded-lg" key={index}>
                        <div className="flex-shrink-0 flex items-center justify-center">
                          <proFeature.icon size={18} className="opacity-100" />
                        </div>
                        <span className="text-sm font-semibold opacity-100">{proFeature.text}</span>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="flex flex-col gap-2 justify-start items-start mt-2">
                  {!userProfile?.isProUser && <SmallButton
                    text="Go Pro"
                    onClick={() => { }}
                    icon={<SparkleIcon className="w-4 h-4" />}
                    iconPosition="end"
                    textColor="text-orange-600"
                    flat={true}
                  />}
                </div>
              </div>
            </div>

          </div>

          {/* <div className="flex flex-col flex-1 flex-grow bg-white p-4 border rounded-2xl border-gray-200 gap-4 overflow-y-auto">
            <h3 className="text-lg font-semibold mb-1">Frequently Asked Questions</h3>

            {faqs.map((faq, index) => (
              <details key={index} className="mb-1" open={index === 0}>
                <summary className="text-base font-medium cursor-pointer select-none">{faq.question}</summary>
                <div className="mt-2 pl-4">
                  <span className="text-base opacity-60">{faq.answer}</span>
                </div>
              </details>
            ))}
          </div> */}
        </div>
      </MainContainer>
    </PageContainer>
  )
}