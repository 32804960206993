import { Navigate, Outlet, Route } from "react-router-dom";
import { AppContainer } from "../../components/containers/AppContainer";
import { useAuth } from "../../components/containers/AuthContext";

const ProtectedRoute = ({ children } : { children? : any}) => {
  const { isAuthenticated } = useAuth();
  const redirectPath = "/login";

  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return (
    <AppContainer>
      {children ? children : <Outlet />}
    </AppContainer>);
};

export default ProtectedRoute;