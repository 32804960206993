import axios from 'axios';
export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
export const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;

const API = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

API.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default API;