import React, { useEffect, useState } from "react";
import { Sidemenu } from "../sidemenu/Sidemenu";

export const AppContainer = ({ children }: { children?: any }) => {

  return (
    <div className="flex flex-row">
      <Sidemenu />
      <main className="flex flex-1 flex-grow overflow-x-hidden h-screen overflow-y-hidden bg-gray-50">
        {children}
      </main>
    </div>

  )
}