import { FC } from "react";

type MainContainerProps = {
  children?: React.ReactNode;
  disableScroll?: boolean;
};

export const MainContainer: FC<MainContainerProps> = ({ children, disableScroll = false }) => {
  return (<div className={`flex flex-1 flex-col px-6 mt-0 pb-6 pt-4 ${disableScroll ? 'overflow-hidden' : 'overflow-y-auto overflow-x-hidden'}`}>
    {children}
  </div>)
};