import React, { FC, useEffect, useRef, useState } from "react";
import { PageContainer } from "../components/containers/PageContainer";
import Header from "../components/ui/header/Header";
import { MainContainer } from "../components/containers/MainContainer";
import { Wand2Icon, ChevronLeftCircleIcon, ChevronLeftIcon, ChevronRightIcon, DownloadCloudIcon, EyeIcon, CheckIcon, SaveIcon, Newspaper, RouteIcon, TrashIcon, FileCheck2Icon } from "lucide-react";
import { Button, SmallButton } from "../components/ui/Button";
import { getHelpArticle, getHelpArticles, HelpArticle, downloadHelpArticle, deleteHelpArticle, updateHelpArticle } from "../lib/helpArticlesService";
import { useMutation, useQuery } from "react-query";
import ReactMarkdown from 'react-markdown';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import HelpArticleEditor from "../components/ui/HelpArticleEditor";
import ArticleStepsToggle from "../components/ui/ArticleStepsToggle";
import { queryClient } from "../lib/queryClient";


export const HelpArticlesDetails = () => {

  const navigate = useNavigate();
  const { articleId } = useParams();

  const [articleContent, setArticleContent] = useState<string>("");

  const { data: article, isError: isArticleError, isLoading: isArticleLoading } = useQuery({
    queryKey: ["helpArticles", articleId],
    queryFn: () => getHelpArticle(articleId as string),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const { mutate: deleteHelpArticleMutation, isLoading: isDeleteHelpArticleLoading } = useMutation(deleteHelpArticle, {
    onSettled: () => queryClient.invalidateQueries(["helpArticles", articleId]),
    onSuccess: () => { navigate("/articles"); }
  });

  const { mutate: updateArticleMutation, isLoading: isUpdateArticleLoading } = useMutation(
    (content: string) => updateHelpArticle(articleId as string, content),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["helpArticles", articleId]);
        
      },
      onError: (error) => {
        console.error("Error updating article:", error);
        alert("Failed to update article. Please try again.");
      },
    }
  );

  useEffect(() => {
    if (article) {
      setArticleContent(article.content || "");
    }
  }, [article]);

  const handleDownload = async () => {
    if (articleId && article) {
      try {
        const blob = await downloadHelpArticle(articleId);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        const cleanedTitle = article.title.replace(/[^a-z0-9]/gi, '_').toLowerCase();
        a.download = `${cleanedTitle}.zip`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        alert('Error downloading article' + error);
      }
    }
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (window.confirm("Are you sure you want to delete this help article?")) {
      deleteHelpArticleMutation(articleId as string);
    }
  };

  const handleSave = () => {
    if (articleContent !== article?.content) {
      updateArticleMutation(articleContent);
    }
  };

  return (
    <PageContainer>
      <Header
        showBackButton
        backButtonUrl={`/articles`}
        title={""}
        headerRight={
          <ArticleStepsToggle articleId={articleId as string} />
        }
      />

      <MainContainer>
        <div className="flex flex-row gap-4">
          <div className="relative flex flex-1 flex-shrink-0 flex-col rounded-2xl overflow-hidden bg-white border border-gray-200 max-w-[900px]">
            <div className="flex flex-grow flex-col overflow-hidden">
              {article && (
                <div className="flex flex-col flex-1 p-6 overflow-hidden">
                  <ReactMarkdown
                    components={{
                      h1: ({ node, ...props }) => <h1 className="text-3xl font-semibold mt-4 mb-3" {...props} />,
                      h2: ({ node, ...props }) => <h2 className="text-2xl font-semibold mt-5 mb-2" {...props} />,
                      p: ({ node, ...props }) => <p className="my-1" {...props} />,
                      ul: ({ node, ...props }) => <ul className="list-disc list-inside my-2" {...props} />,
                      ol: ({ node, ...props }) => <ol className="list-decimal list-inside my-2" {...props} />,
                      li: ({ node, ...props }) => <li className="ml-4 mb-2" {...props} />,
                      a: ({ node, ...props }) => <a className="text-green-800 hover:underline" {...props} />,
                      img: ({ node, ...props }) => (
                        <img className="my-4 rounded-lg border border-gray-200 max-w-full h-auto" {...props} alt={props.alt || ''} src={props.src === 'output.gif' ? article.mediaOutput : props.src} />
                      ),
                    }}
                  >
                    {articleContent}
                  </ReactMarkdown>

                </div>
              )}
            </div>
          </div>

          <div className="flex flex-1 flex-grow flex-shrink-0 flex-col gap-2 rounded-2xl bg-white border border-gray-200 p-6">
            <div className="flex flex-row items-center h-10">
              <div className="flex flex-col">
                <span className="text-base font-semibold">Edit Article</span>
                <span className="text-base opacity-60">You can edit the article content here.</span>
              </div>
              <div className="flex-grow" />
              <div className="flex flex-row border border-gray-200 rounded-lg">

                <SmallButton
                  icon={<TrashIcon size={16} />}
                  text="Delete"
                  onClick={handleDelete}
                  isLoading={isDeleteHelpArticleLoading}
                  flat={true}
                  border={false}
                />
                <SmallButton
                  icon={<DownloadCloudIcon size={16} />}
                  text="Download"
                  onClick={handleDownload}
                  flat={true}
                  border={false}
                />

                <div className="w-[1px] h-8 bg-gray-200 flex-shrink-0" />

                <SmallButton
                  icon={(articleContent !== article?.content) 
                    ? <SaveIcon size={16} className="text-orange-600" /> 
                    : <SaveIcon size={16}  />}
                  text="Save changes"
                  onClick={handleSave}
                  flat={true}
                  border={false}
                  iconPosition="end"
                  disabled={articleContent === article?.content}
                  isLoading={isUpdateArticleLoading}
                />
              </div>
            </div>
            <HelpArticleEditor
              initialArticleContent={article?.content || ""}
              setArticleContent={setArticleContent}
              articleId={articleId as string}
            />
          </div>

        </div>

      </MainContainer>
    </PageContainer>
  )
};
