import React, { useEffect, useState } from "react";
import { AuthPage } from "../components/auth/AuthPage";
import { Link, Navigate } from "react-router-dom";
import { CTAButton } from "../components/ui/Button";
import { Input } from "../components/ui/Input";
import { FormSpacer } from "../components/ui/FormSpacer";

import { login } from '../lib/authService';
import { useMutation } from "react-query";
import { useAuth } from "../components/containers/AuthContext";

export const Login = () => {
  const { isAuthenticated, loginSuccess} = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { mutate: loginMutation, isLoading } = useMutation(login, {});
  const runLogin = () => {
    loginMutation({ email, password, loginSuccess });
  };
  
  if (isAuthenticated) {
    return <Navigate replace to={{ pathname: "/" }} />
  }
  return (
    <AuthPage>
      <header className="flex-1 flex flex-col gap-y-4 items-center">
        <img
            src="/logo_black.png"
            alt="Support as a Service"
            width={40}
            height={40}
        />

        <h1 className="text-2xl font-bold mb-4">Welcome back</h1>
      </header>

      <div className="my-4">
        <Input
          label="Email"
          type="email"
          placeholder="john.doe@example.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth={true}
        />
        <FormSpacer />
        <Input
          label="Password"
          type="password"
          placeholder="**********"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth={true}
        />
        <FormSpacer />
        <CTAButton text="Login" onClick={runLogin} fullWidth isLoading={isLoading}/>
      </div>

      <footer className="flex-1 flex flex-col gap-y-4 items-center">
        <Link to={"/register"}>
          <span className="text-orange-600 hover:underline">Don't have an account?</span>
        </Link>
      </footer>
    </AuthPage>
  )
};