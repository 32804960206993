"use client";
import { ChevronLeftIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const BackButton: React.FC<{ backButtonUrl?: string }> = ({ backButtonUrl = undefined }) => {
  const [hideBackButton, setHideBackButton] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleGoBack = () => {
    if (backButtonUrl) {
      navigate(backButtonUrl);
      
    } else {
      window.history.back();
    }
  };

  useEffect(() => {
    if (window.history.state == null) {
      setHideBackButton(true);
    }
  }, []);


  return (
    <>
      {!hideBackButton && <button onClick={handleGoBack} className="flex items-center pr-4">
        <ChevronLeftIcon className='w-6 h-6' />
      </button>}
    </>
  );
};

export default BackButton;
