import { FC } from "react";
import { Link } from "react-router-dom";

type AuthPageProps = {
  children?: React.ReactNode;
};

export const AuthPage : FC<AuthPageProps> = ({ children }) => {
  
  return (<div className="flex flex-1 bg-gray-900 justify-center items-center">
    <div className="flex flex-col w-[400px]">
      <div className="bg-white rounded-lg shadow-lg p-6">
        {children}
      </div>
      <div className="text-sm text-white opacity-60 p-6 text-center">
        By using our service, you agree to our{' '}
        <Link to="https://happysupport.ai/legal/terms-of-service" className="text-white underline" target="_blank">
          Terms of Service
        </Link>
        ,{' '}
        <Link to="https://happysupport.ai/legal/privacy-notice" className="text-white underline" target="_blank">
          Privacy Notice
        </Link>
        , and{' '}
        <Link to="https://happysupport.ai/legal/refund-policy" className="text-white underline" target="_blank">
          Refund Policy
        </Link>.
      </div>
    </div>
  </div>);
};