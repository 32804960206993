import React, { FC, useEffect, useState } from "react";
import { PageContainer } from "../components/containers/PageContainer";
import Header from "../components/ui/header/Header";
import { MainContainer } from "../components/containers/MainContainer";
import { BugIcon, CheckCircle2, Circle, CircleDashed, CircleIcon, DownloadCloudIcon, ExternalLinkIcon, Loader, Loader2, MousePointerClick, Newspaper, PlayIcon, RouteIcon } from "lucide-react";
import { Button, SmallButton } from "../components/ui/Button";
import { generateHelpArticle, getHelpArticles, HelpArticle } from "../lib/helpArticlesService";
import { useQuery } from "react-query";
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { EmptyState } from '../components/ui/EmptyState';

export const HelpArticles = () => {

  const { 
    data: helpArticles, 
    isError: isHelpArticlesError, 
    isLoading: isHelpArticlesLoading,
    isSuccess: isHelpArticlesSuccess,
    refetch: refechHelpArticles,
    isRefetching: isHelpArticlesRefetching,
  } = useQuery("helpArticles", getHelpArticles, {
    initialData: [],
  });

  const navigate = useNavigate();

  return (
    <PageContainer>
      <Header
        title="Articles"
        subtitle="You can see all the help articles you recorded here."
      />

      <MainContainer>
        <div className="flex flex-col flex-1">
          <div className="flex flex-col rounded-2xl overflow-hidden bg-white border border-gray-200">
            {helpArticles && helpArticles.length > 0 && (
              helpArticles.map((helpArticle) => (
                <HelpArticleItem key={helpArticle.id} helpArticle={helpArticle} />
              ))
            )}

            {isHelpArticlesSuccess && helpArticles.length < 1 && (<EmptyState
              icon={Newspaper}
              title="No help articles yet"
              subtitle="Create your first help article by recording a process."
              button={{
                text: "Get started",
                onClick: () => {
                  navigate("/get-started");
                }
              }}
            />)}

            {isHelpArticlesError && <EmptyState
              icon={BugIcon}
              title="Error while loading articles"
              subtitle="Try reloading the page or try again later."
              button={{
                text: "Reload",
                onClick: () => {
                  refechHelpArticles();
                },
                loading: isHelpArticlesRefetching,
              }}
            />}
            {isHelpArticlesLoading && (<EmptyState
              icon={Loader}
              title="Loading articles ..."
              subtitle="Fetching your recorded articles"
            />)}
          </div>
        </div>
      </MainContainer>
    </PageContainer>
  )
};

type HelpArticleItemProps = {
  helpArticle: HelpArticle;
}

export const HelpArticleItem: FC<HelpArticleItemProps> = ({
  helpArticle,
}) => {
  const location = useLocation();
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { mutate: generateHelpArticleMutation, isLoading: isGenerateHelpArticleLoading } = useMutation(generateHelpArticle, {
    onSettled: () => queryClient.invalidateQueries(["helpArticles", helpArticle.id]),
  });

  const handleGenerateArticle = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    generateHelpArticleMutation(helpArticle.id);
  };

  const handleOpenArticle = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/articles/${helpArticle.id}`);
  };

  const handleOpenSteps = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/articles/${helpArticle.id}/steps`);
  };

  return (
    <Link to={(helpArticle.content !== null && helpArticle.content !== undefined) ? `/articles/${helpArticle.id}` : `/articles/${helpArticle.id}/steps`}
      className={`flex flex-col p-4 border-b-zinc-200 hover:bg-zinc-100 transition-colors border-b last:border-b-0`}>
      <div className="flex flex-row gap-4 items-center">
        {helpArticle.content !== null && helpArticle.content !== undefined
          ? (<CheckCircle2 size={22} className="text-orange-600" />)
          : (<Loader2 size={22} className="text-black animate-spin opacity-40" />)}

        <div className="flex flex-col overflow-hidden">
          <h3 className="text-base font-semibold truncate">{helpArticle.title}</h3>
          {(helpArticle.content !== null && helpArticle.content !== undefined) ? (
            <span className="text-sm text-black opacity-40">{new Date(helpArticle.createdAt).toLocaleString()}</span>
          ) : (
            <span className="text-sm text-black opacity-60">Generating article...</span>
          )}
        </div>
        <div className="flex-grow" />
        <div className="flex flex-row items-center pl-1 gap-2">
          <SmallButton
            onClick={handleGenerateArticle}
            text="Generate"
            icon={<PlayIcon size={16} className="text-blue-600" />}
            iconPosition="start"
          />
          {helpArticle.content !== null && helpArticle.content !== undefined && (
            <>
              <SmallButton
                onClick={handleOpenArticle}
                text="Open Article"
                icon={<Newspaper size={16} className="text-orange-600" />}
                iconPosition="start"
              />
            </>)}

          <SmallButton
            onClick={handleOpenSteps}
            text="Steps"
            icon={<RouteIcon size={16} className="text-black opacity-80" />}
            iconPosition="start"
          />

        </div>
      </div>
    </Link>
  );
};