import React from "react"
import { PageContainer } from "../components/containers/PageContainer"
import Header from "../components/ui/header/Header";
import { MainContainer } from "../components/containers/MainContainer"
import { Button, SmallButton } from "../components/ui/Button";
import { AppWindowIcon, CalendarIcon, CheckSquareIcon, ChromeIcon, ClockIcon, CreditCard, Download, DownloadIcon, FilmIcon, GlobeIcon, LampDeskIcon, RefreshCwIcon, SparkleIcon, StarIcon, UsersIcon, Wand2Icon, XCircleIcon } from "lucide-react";

export const GetStarted: React.FC = () => {

  const proFeatures = [
    { icon: CalendarIcon, text: '50 articles/month' },
    { icon: Wand2Icon, text: 'Smart-Edit' },
    { icon: XCircleIcon, text: 'Cancel anytime' },
    { icon: ClockIcon, text: '2 minutes/article' },
  ];

  return (
    <PageContainer>
      <Header
        title="Get Started"
      />

      <MainContainer>
        <div className="flex flex-col gap-4">

          <div className="flex flex-row gap-4 items-center">
            <div className="h-10 w-10 bg-zinc-300 rounded-full flex items-center justify-center">
              <span className="text-black text-lg font-bold">1</span>
            </div>
            <div className="flex flex-row flex-1 bg-white p-4 border rounded-2xl border-gray-200 items-center gap-4">
              <div className="flex flex-col flex-1 flex-grow">
                <h3 className="text-lg font-semibold">Install Recorder</h3>
                <span className="text-base opacity-60">Download and install the Happy Recorder, available as a Chrome extension.</span>
              </div>
              <div className="flex flex-row gap-2">
                <SmallButton
                  text="Download Chrome Extension"
                  onClick={() => { }}
                  icon={<ChromeIcon className="w-4 h-4" />}
                  textColor="text-orange-600"
                />
                <SmallButton
                  text="Windows (soon)"
                  onClick={() => { }}
                  icon={<AppWindowIcon className="w-4 h-4" />}
                  disabled={true}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-row gap-4 items-center">
            <div className="h-10 w-10 bg-zinc-300 rounded-full flex items-center justify-center">
              <span className="text-black text-lg font-bold">2</span>
            </div>
            <div className="flex flex-row flex-1 bg-white p-4 border rounded-2xl border-gray-200 items-center gap-4">
              <div className="flex flex-col flex-1">
                <div className="flex justify-between">
                  <h3 className="text-lg font-semibold">Record steps</h3>
                </div>
                <div className="text-base opacity-60">
                  <ul className="list-disc list-inside mt-2">
                    <li>Open the extension in your Chrome browser and click "Start Recording"</li>
                    <li>Navigate through the task you want to document, every click and action will be automatically recorded</li>
                    <li>Click "Stop Recording" when you're done</li>
                    <li>Remove unnecessary steps or add comments to clarify (optional)</li>
                    <li>When satisfied click "Upload" to save your guide</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row gap-4 items-center">
            <div className="h-10 w-10 bg-zinc-300 rounded-full flex items-center justify-center">
              <span className="text-black text-lg font-bold">3</span>
            </div>
            <div className="flex flex-row flex-1 bg-white p-4 border rounded-2xl border-gray-200 items-center gap-4">
              <div className="flex flex-col flex-1">
                <h3 className="text-lg font-semibold">Recording turns into article automatically 🪄</h3>
                <span className="flex flex-col text-base opacity-60">
                  <span className="mb-4">Once uploaded, your recording will automatically turn into an article with a nice looking gif explaining the steps.</span>
                  <span>Now you can easily edit, share and use your guide:</span>
                  <ul className="list-disc list-inside">
                    <li>Make changes by hand or using Smart-Edit by selecting the text you want to edit and telling what to change</li>
                    <li>Download the article as markdown file and use it in your documentation</li>
                  </ul>
                </span>
              </div>
            </div>
          </div>

          <div className="flex flex-row gap-4 items-center">
            <div className="h-10 w-10 bg-zinc-300 rounded-full flex items-center justify-center">
              <span className="text-black text-lg font-bold">4</span>
            </div>
            <div className="flex flex-row flex-1 bg-white p-4 border rounded-2xl border-gray-200 gap-4">
              <div className="flex flex-col flex-1">
                <h3 className="text-lg font-semibold">Go Pro and unlock all features</h3>
                <span className="flex flex-col text-base opacity-60">
                  <span>You can generate up to 3 articles once with the free tryout plan. Go Pro to unlock all features and generate 50 articles per month.</span>
                </span>

                <div className="flex flex-row gap-12 mt-4 text-orange-600">
                  {proFeatures.map((proFeature, index) => (
                    <div className="flex items-center gap-2" key={index}>
                      <div className="flex-shrink-0 flex items-center justify-center">
                        <proFeature.icon size={18} className="opacity-100" />
                      </div>
                      <span className="text-base font-semibold opacity-100">{proFeature.text}</span>
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex flex-col gap-2 justify-start items-start mt-2">
                <SmallButton
                  text="Go Pro, just 49€/month"
                  onClick={() => { }}
                  icon={<SparkleIcon className="w-4 h-4" />}
                  iconPosition="end"
                  textColor="text-orange-600"
                  flat={true}
                />
              </div>
            </div>
          </div>

        </div>
      </MainContainer>
    </PageContainer>
  )
}