import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getUserProfile } from "../../lib/profileService";
import { UserCircle2Icon } from "lucide-react";

type UserContainerProps = {
  isSmallScreen: boolean;
  mode?: "light" | "dark";
  clickable?: boolean;
};

export const UserContainer = ({
  isSmallScreen,
  mode = "light"
}: UserContainerProps) => {
  const { data: userProfile, isError: isUserProfileError, isLoading: isUserProfileLoading } = useQuery("userProfile", getUserProfile, {
    initialData: null,
  });

  return (
  <Link 
    to={"/account"}
    className={`flex flex-row gap-2 hover:bg-white hover:bg-opacity-10 rounded-md py-1 px-2 cursor-pointer items-center`}
  >
  {!isSmallScreen ? (<>
    {/* <div className={`h-9 w-9 flex-shrink-0 rounded-md overflow-hidden opacity-40 ${mode === "light" ? "bg-white" : "bg-zinc-200"}`}></div> */}
    <div className={`flex-1 overflow-hidden flex flex-col ${mode === "light" ? "text-white" : "text-black"}`}>
      <h2 className="text-md truncate">
        {(isUserProfileLoading && userProfile) ? "Loading..." : (userProfile?.firstName) + " " + userProfile?.lastName}
      </h2>
      <p className="text-sm opacity-60 truncate">
        {isUserProfileLoading ? "Loading..." : userProfile?.email}
      </p>
    </div>
    </>)
    :
    (<div>
      <UserCircle2Icon size={22} stroke="white" />
    </div>)}
  </Link>);
};