import React, { FC, useEffect, useState } from "react";
import { PageContainer } from "../components/containers/PageContainer";
import Header from "../components/ui/header/Header";
import { MainContainer } from "../components/containers/MainContainer";
import { CreditCardIcon, DownloadCloudIcon, PlayIcon } from "lucide-react";
import { Button, SmallButton } from "../components/ui/Button";
import { generateHelpArticle, getHelpArticles, HelpArticle } from "../lib/helpArticlesService";
import { useQuery } from "react-query";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { Trash2Icon } from "lucide-react";
import { deleteHelpArticle } from "../lib/helpArticlesService";
import { useMutation, useQueryClient } from "react-query";
import { HelpArticlesDetails } from "./HelpArticlesDetails";
import { getUserProfile } from "../lib/profileService";
import { useAuth } from "../components/containers/AuthContext";
import { queryClient } from "../lib/queryClient";
import { createBillingPortalSession } from "../lib/paymentService";


export const Account = () => {
  const { data: userProfile, isError: isUserProfileError, isLoading: isUserProfileLoading } = useQuery("userProfile", getUserProfile, {
    initialData: null,
  });

  const { logout } = useAuth();

  const { mutate: createBillingPortalSessionMutation, isLoading: isCreateBillingPortalSessionLoading } = useMutation("billingPortalSession", createBillingPortalSession, {
    onSettled: () => {
      queryClient.invalidateQueries("billingPortalSession");
    },
    onSuccess: (data) => {
      window.location.href = data.redirectUrl;
    },
  });

  const openBillingPortal = () => {
    createBillingPortalSessionMutation();
  };

  return (
    <PageContainer>
      <Header
        title="Account"
      />

      <MainContainer>
        <div className="flex flex-col flex-1">
          <div className="flex flex-row gap-4">
            <div className="flex flex-1 flex-col rounded-2xl overflow-hidden bg-white border border-gray-200 p-4">
              <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-1">
                  <h2 className="text-xl font-semibold">{userProfile?.firstName} {userProfile?.lastName}</h2>
                  <span className="text-base opacity-60">{userProfile?.email}</span>
                </div>
              </div>
              <div className="mt-4">
                <SmallButton
                  onClick={logout}
                  text="Logout"
                  fullWidth={false}
                  textColor="text-red-500"
                />
              </div>
            </div>

            <div className="flex flex-1 flex-col rounded-2xl overflow-hidden bg-white border border-gray-200 p-4">
              <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-1">
                  <h2 className="text-xl font-semibold">Billing</h2>
                  <span className="text-base opacity-60">You can manage your subscription and billing information here.</span>
                  <div className="mt-4">
                    <SmallButton
                      text="Open Billing Portal"
                    onClick={openBillingPortal}
                    icon={<CreditCardIcon />}
                  />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </MainContainer>
    </PageContainer>
  )
};
