import React, { useEffect, useRef } from 'react';

interface InputProps {
  type?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  value: string | undefined;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  label?: string;
  autoFocus?: boolean;
  className?: string;
}

export const MultilineInput: React.FC<InputProps> = ({
  fullWidth = false,
  disabled,
  placeholder,
  value,
  onChange,
  label,
  autoFocus = false,
  className,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className='flex flex-1 flex-col'>
      {label && <label className="text-sm font-semibold text-black ml-1 mb-1">{label}</label>}
      <textarea
        ref={textareaRef}
        className={`bg-white px-4 mx-1 ${fullWidth ? "w-full" : ""} py-2 rounded-md border border-zinc-300 transition 
        duration-300 ease-in-out
        focus:outline-none focus:border-green-800 focus:ring-0 resize-y min-h-[100px] ${className}`}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
        autoFocus={autoFocus}
        style={{ overflow: 'auto' }}
      />
    </div>
  );
};

export const URLInput: React.FC<InputProps> = ({
  type = "text",
  fullWidth = false,
  disabled,
  placeholder,
  value,
  onChange,
  label,
  autoFocus = false,
}) => {
  const getSafeUrl = (url: string) => {
    let safeUrl = "";
    if ("https://".startsWith(url)) {
      safeUrl = url;
    }
    else if (!url.startsWith('http')) {
      safeUrl = `https://${url}`;
    } else {
      safeUrl = url;
    }

    return(safeUrl);
  };

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const safeUrl = getSafeUrl(e.target.value);
    onChange({target: {value: safeUrl}} as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <div className='flex flex-col'>
      {label && <label className="text-sm font-semibold text-black ml-1 mb-1">{label}</label>}
      <input
        className={`bg-white h-12 px-4 mx-1 ${fullWidth ? "w-full" : ""} py-2 rounded-md border transition 
        duration-300 ease-in-out whitespace-nowrap border-zinc-300
        focus:outline-none focus:border-green-900 focus:ring-0`}
        type={type}
        value={value}
        onChange={handleUrlChange}
        placeholder={placeholder}
        disabled={disabled}
        autoFocus={autoFocus}
      >
      </input>
    </div>
  );
};

export const Input: React.FC<InputProps> = ({
  type = "text",
  fullWidth = false,
  disabled,
  placeholder,
  value,
  onChange,
  label,
  autoFocus = false,
}) => {
  return (
    <div className={`flex flex-col ${fullWidth ? "flex-1 w-full" : ""}`}>
      {label && <label className="text-sm font-semibold text-black ml-1 mb-1">{label}</label>}
      <input
        className={`bg-white h-12 px-4 ${fullWidth ? "w-full" : ""} py-2 rounded-md border transition 
        duration-300 ease-in-out whitespace-nowrap border-zinc-300
        focus:outline-none focus:border-green-900 focus:ring-0`}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        autoFocus={autoFocus}
      >

      </input>
    </div>
  );
};