import API from './axios';

const ENDPOINT = '/help-articles';

export type HelpArticleStep = {
  id: string;
  index: number;
  summary?: string;
  imageRaw: string;
  comment: string;
  contextHeight: number;
  contextWidth: number;
  positionX: number;
  positionY: number;
  endPositionX: number;
  endPositionY: number;
  action: string;
};

export type HelpArticle = {
  id: string;
  mediaOutput?: string;
  title: string;
  content?: string;
  steps?: HelpArticleStep[];
  organizationId: string;
  createdAt: string;
  updatedAt: string;
};

export type SmartEditResponse = {
  editedMarkdown: string;
};

export type HelpArticleUsage = {
  usage: {
    overall: number;
    last30Days: number;
    last7Days: number;
  };
  monthlyLimit: number;
};

export const getHelpArticles = async (): Promise<HelpArticle[]> => 
  (await API.get(`${ENDPOINT}`)).data;

export const getHelpArticle = async (id: string): Promise<HelpArticle> =>
  (await API.get(`${ENDPOINT}/${id}`)).data;

export const deleteHelpArticle = async (id: string): Promise<void> =>
  await API.delete(`${ENDPOINT}/${id}`);

export const generateHelpArticle = async (id: string): Promise<string> =>
  (await API.post(`${ENDPOINT}/${id}/generate`)).data;

export const smartEditHelpArticle = async (id: string, markdownText: string, changeRequest: string): Promise<SmartEditResponse> =>
  (await API.post(`${ENDPOINT}/${id}/smart-edit`, { markdownText, changeRequest })).data;

export const downloadHelpArticle = async (id: string): Promise<Blob> => {
  const response = await API.get(`${ENDPOINT}/${id}/download`, {
    responseType: 'blob'
  });
  return response.data;
};

export const getHelpArticleUsage = async (): Promise<HelpArticleUsage> =>
  (await API.get(`${ENDPOINT}/usage`)).data;

export const updateHelpArticle = async (id: string, content: string): Promise<void> =>
  await API.put(`${ENDPOINT}/${id}`, { content });

