import { Route, createRoutesFromElements, createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";

import { Login } from './routes/Login';
import { Register } from './routes/Register';
import ProtectedRoute from './routes/security/ProtectedRoute';
import { HelpArticles } from "./routes/HelpArticles";
import Plans from "./routes/Plans";
import { HelpArticlesDetails } from "./routes/HelpArticlesDetails";
import { HelpArticlesDetailsSteps } from "./routes/HelpArticlesDetailsSteps";
import { Account } from "./routes/Account";
import { Usage } from "./routes/Usage";
import { GetStarted } from "./routes/GetStarted";
import PlansCheckout from "./routes/PlansCheckout";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route path="/" element={<Navigate to="/articles" />} />
        
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />

        <Route element={<ProtectedRoute />}>
          <Route path="get-started" element={<GetStarted />} />
          <Route path="account" element={<Account />} />
          <Route path="articles" element={<HelpArticles />} />
          <Route path="usage" element={<Usage />} />
          <Route path="plans" element={<Plans />} />
          <Route path="plans/checkout" element={<PlansCheckout />} />
          <Route path="articles/:articleId" element={<HelpArticlesDetails />} />
          <Route path="articles/:articleId/steps" element={<HelpArticlesDetailsSteps />}>
            <Route path=":stepId" element={<HelpArticlesDetailsSteps />} /> 
          </Route>
        </Route>
      </Route>
    )
  );

  return (
    <RouterProvider router={router} />
  );
}

export default App;
