import { ArrowRightIcon, BarChart2Icon, BarChartBig, BotMessageSquareIcon, CableIcon, CreditCardIcon, FileOutputIcon, FlaskConicalIcon, LibraryBigIcon, LifeBuoyIcon, LinkIcon, MessageCircle, MessageCircleQuestionIcon, NewspaperIcon, PieChart, ReceiptTextIcon, RocketIcon, SettingsIcon, SparklesIcon, StickerIcon, TargetIcon, TrendingUpIcon } from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { UserContainer } from "../auth/UserContainer";
import { SmallButton } from "../ui/Button";

type SidenavEntryProps = {
  separator?: boolean;
  title: string;
  icon?: React.ReactNode;
  href?: string;
  bottom?: boolean;
};

const SIDENAV_ITEMS: SidenavEntryProps[] = [  
  // {
  //   separator: true,
  //   title: 'Help Articles',
  // },
  {
    title: 'Get Started',
    icon: <RocketIcon size={22} />,
    href: '/get-started',
  },
  {
    title: 'Articles',
    icon: <LibraryBigIcon size={22} />,
    href: '/articles',
  },
  {
    title: 'Plans',
    icon: <CreditCardIcon size={22} />,
    href: '/plans',
  },
  {
    title: 'Usage',
    icon: <BarChart2Icon size={22} />,
    href: '/usage',
  },
];

export const Sidemenu = () => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1080px)' });

  return (
    <aside className={`flex flex-shrink-0 ${isSmallScreen ? 'w-14' : 'w-60'} bg-black h-screen`}>
      <div className="flex flex-col w-full">
        <div className="flex flex-row space-x-3 items-center justify-start px-4 h-12 w-full">
          <div>
            <img
              src="/logo_medium.png"
              alt="HappySupport Logo"
              className="h-8 w-8 object-contain"
            />
          </div>
        </div>

        <div className="flex flex-1 flex-col py-4 space-y-2 px-2 ">

          {SIDENAV_ITEMS.filter(x => !x.bottom).map((item, index) => (
            item.separator
              ? <div key={index + "x"} className="pt-4 first:p-0 select-none">
                {/* <span className={`text-sm font-semibold text-white px-2 ${isSmallScreen ? 'hidden' : ''}`}>{item.title}</span> */}
                <span className={`text-sm font-semibold text-white px-2`}>{isSmallScreen ? "" : item.title}</span>
              </div>
              : <SidemenuEntry key={index + "x"} icon={item.icon} label={item.title} href={item.href || ""} isSmallScreen={isSmallScreen} />
          ))}

          <div className="flex-grow" />
          

          {SIDENAV_ITEMS.filter(x => x.bottom).map((item, index) => (
            <SidemenuEntry key={index + "y"} icon={item.icon} label={item.title} href={item.href || ""} isSmallScreen={isSmallScreen} />
          ))}

          <div className="p-3 rounded-lg border-2 border-white border-opacity-10 text-white flex flex-col gap-3 hover:bg-white hover:bg-opacity-5 cursor-pointer transition-colors duration-200">
            <div className="flex justify-between items-center">
              <span className="text-sm font-semibold">Article Usage</span>
              <ArrowRightIcon size={16} />
            </div>
            <div className="flex items-center gap-3">
              <div className="flex flex-row text-sm items-center">
                <NewspaperIcon size={16} className="mr-2"/>
                <span className="font-semibold">3</span>
                <span className="opacity-60">/</span>
                <span className="font-semibold">50</span>
                <span className="ml-2 opacity-60">articles used</span>
              </div>
            </div>
            <div className="w-full h-1 bg-white bg-opacity-10 rounded-full overflow-hidden">
              <div className="h-full bg-green-500 rounded-full" style={{ width: '3%' }}></div>
            </div>
            {/* <SmallButton text="Upgrade Plan" onClick={() => {}} /> */}
          </div>

          <UserContainer isSmallScreen={isSmallScreen}/>

        </div>
      </div>
    </aside>
  )
};

type SidemenuEntryProps = {
  icon: React.ReactNode,
  label: string,
  href: string,
  isSmallScreen: boolean
};

const SidemenuEntry = ({ icon, label, href, isSmallScreen }: SidemenuEntryProps) => {
  const location = useLocation();
  const active = location.pathname.startsWith(href) && (location.pathname[href.length] === '/' || location.pathname[href.length] === undefined);

  return (
    <Link to={href}>
      <span
        className={`flex flex-row h-[40px] items-center space-x-3 py-2 px-2 rounded-md text-white ${active ? 'bg-white bg-opacity-15' : ''}`}
      >
        {icon}
        <span className={`${isSmallScreen ? 'hidden' : ''}`}>{label}</span>
      </span>
    </Link>
  )
};
