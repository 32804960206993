import React from 'react';
import BackButton from './HeaderBackButton';

interface HeaderProps {
  title: string;
  subtitle?: string;
  headerRight?: React.ReactNode;
  showBackButton?: boolean;
  backButtonUrl?: string;
}

const Header: React.FC<HeaderProps> = ({ title, subtitle, headerRight, showBackButton, backButtonUrl = undefined }) => {
  return (
    // <header className='flex flex-row px-6 py-6 items-center '>
    //   {showBackButton && (
    //     <BackButton />
    //   )}
    //   <div className='flex flex-1 flex-col'>
    //     <h1 className='text-2xl font-medium'>{title}</h1>
    //     {subtitle && <span className='text-md text-zinc-500'>{subtitle}</span>}
    //   </div>
    //   <div className='flex items-center'>
    //     {headerRight}
    //   </div>
    // </header>
    <header className='flex flex-row px-6 py-2 items-center border-b border-b-zinc-300 h-16 bg-white'>
      {showBackButton && (
        <BackButton backButtonUrl={backButtonUrl} />
      )}
      <div className='flex flex-1 flex-row items-center gap-4'>
        <h1 className='text-2xl font-semibold'>{title}</h1>
        {/* {subtitle && <span className='text-md text-zinc-500'>{subtitle}</span>} */}
      </div>
      <div className='flex items-center'>
        {headerRight}
      </div>
    </header>
  );
};

export default Header;