import React from 'react';

interface LoadingIndicatorProps {
    size?: 'small' | 'medium' | 'large';
    color?: string;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ size = "small", color = "border-gray-900" }) => {
    let sizeClass = '';

    switch (size) {
        case 'small':
            sizeClass = 'h-4 w-4';
            break;
        case 'medium':
            sizeClass = 'h-6 w-6';
            break;
        case 'large':
            sizeClass = 'h-8 w-8';
            break;
    }

    return (
        <div className={`spinner ${sizeClass} border-t-2 border-b-2 ${color} rounded-full animate-spin`} />
    );
};

export default LoadingIndicator;