import API from './axios';

const ENDPOINT = '/auth';

type LoginProps = {
  email: string;
  password: string;
  loginSuccess: (token: string) => void;
};

type RegisterProps = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  loginSuccess: (token: string) => void;
};


export const login = async ({email, password, loginSuccess} : LoginProps) => {
  const response = await API.post(`${ENDPOINT}/login`, { email, password });
  if (response.status === 200) {
    loginSuccess(response.data.token);
  } else {
    throw new Error(response.data.message || 'Error logging in');
  }
  return response.data;
};

export const register = async ({
    email,
    password,
    firstName,
    lastName,
    loginSuccess,
} : RegisterProps) => {
  const response = await API.post(`${ENDPOINT}/register`, {
    email,
    password,
    firstName,
    lastName,
  });
  if (response.status === 200) {
    loginSuccess(response.data.token);
  } else {
    throw new Error(response.data.message || 'Error registering');
  }
  return response.data;
};