import React, { createContext, useContext, useState, useEffect } from 'react';
import { queryClient } from '../../lib/queryClient';

const AuthContext = createContext({
    isAuthenticated: !!localStorage.getItem('token'),
    loginSuccess: (token: string) => {},
    logout: () => {},
});

export const AuthProvider = ({ children } : { children: any }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem('token')
  );

  const loginSuccess = (token: string) => {
    localStorage.setItem('token', token);
    setIsAuthenticated(true);
  };

  const logout = () => {
    localStorage.removeItem('token');
    queryClient.clear();
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, loginSuccess, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
