import React from 'react';
import LoadingIndicator from './LoadingIndicator';

interface ButtonProps {
  text?: string;
  onClick: (e: React.MouseEvent) => void;
  isLoading?: boolean;
  textColor?: string;
  bgColor?: string;
  hoverState?: string;
  fullWidth?: boolean;
  border?: boolean;
  small?: boolean;
  icon?: React.ReactNode;
  iconPosition?: 'start' | 'end';
  flat?: boolean;
  disabled?: boolean; // Added disabled property
}

export const CTAButton: React.FC<ButtonProps> = ({
  text,
  onClick,
  isLoading,
  textColor = 'text-white',
  bgColor = 'bg-orange-600',
  hoverState = 'hover:bg-orange-700',
  fullWidth = false,
  border = false,
  icon,
  iconPosition
}) => {
  return (
    <Button
      text={text}
      onClick={onClick}
      isLoading={isLoading}
      textColor={textColor}
      bgColor={bgColor}
      hoverState={hoverState}
      fullWidth={fullWidth}
      border={border}
      icon={icon}
      iconPosition={iconPosition}
    />
  );
};

export const SmallButton: React.FC<ButtonProps> = (props) => {
  return <Button {...props} small={true} />;
};

export const FlatButton: React.FC<ButtonProps> = (props) => {
  return <Button {...props} flat={true} />;
};

export const Button: React.FC<ButtonProps> = ({ 
  text,
  onClick,
  isLoading,
  textColor = 'text-black',
  bgColor = 'bg-white',
  hoverState = 'hover:bg-gray-100',
  fullWidth = false,
  border = true,
  small = false,
  icon,
  iconPosition = 'start',
  flat = false,
  disabled = false, // Added disabled property
}) => {
  const iconElement = icon && (
    <span>{icon}</span>
  );

  const content = isLoading ? (
    <LoadingIndicator size={small ? "small" : "medium"} color={textColor} />
  ) : (
    <div className="flex items-center justify-center gap-2">
      {icon && (iconPosition === 'start' || !text) && <span className={textColor}>{icon}</span>}
      {text && <span className={`font-semibold ${textColor}`}>{text}</span>}
      {icon && iconPosition === 'end' && text && <span className={textColor}>{icon}</span>}
    </div>
  );

  return (
    <button
      className={`${bgColor} ${small ? 'h-8 px-3 text-sm' : 'h-10 px-4'} ${fullWidth && "w-full"} ${!text && !small && 'p-2'} ${!text && small && 'p-1'} rounded-lg ${!flat && 'shadow-sm'} ${border && "border border-zinc-300"} ${hoverState} transition 
              duration-300 ease-in-out whitespace-nowrap text-center flex justify-center items-center ${disabled && 'opacity-50 cursor-not-allowed'}`} // Added disabled styles
      onClick={disabled ? undefined : onClick} // Disable onClick if disabled
      disabled={isLoading || disabled} // Disable button if loading or disabled
    >
      {content}
    </button>
  );
};