import API from './axios';
import { InsightEngine } from './insightEngineService';
import { KnowledgeSource } from './knowledgeSourceService';

const ENDPOINT = '/profile';

export type UserProfile = {
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    activeOrganizationId: string,
    isProUser: boolean,
};

export const getUserProfile = async () : Promise<UserProfile> =>
    (await API.get(`${ENDPOINT}/user`)).data;


