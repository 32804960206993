import { LucideLoader2, NewspaperIcon, RouteIcon } from "lucide-react";
import { Link, useLocation } from "react-router-dom";

const ArticleStepsToggle = ({ articleId, articleLoading = false }: { articleId: string, articleLoading?: boolean }) => {
  const location = useLocation();

  return (<div className="inline-flex p-1 rounded-xl bg-gray-100 border border-gray-200">
    {articleLoading ?
    <div
    className={`flex flex-row items-center px-8 py-1 text-sm font-semibold rounded-lg transition-all duration-200 text-gray-700'`}
    >
      <LucideLoader2 size={16} className="animate-spin mr-2" />
      Generating ...
    </div>
    : <Link
      to={`/articles/${articleId}`}
      className={`flex flex-row items-center px-8 py-1 text-sm font-semibold rounded-lg transition-all duration-200 ${location.pathname === `/articles/${articleId}`
        ? 'border border-orange-600 bg-white text-orange-600'
        : 'text-gray-700 hover:bg-gray-200'
        }`}
    >
      <NewspaperIcon size={16} className="mr-2" />
      Article
    </Link>}
    <Link
      to={`/articles/${articleId}/steps`}
      className={`flex flex-row items-center px-8 py-1 text-sm font-semibold rounded-lg transition-all duration-200 ${location.pathname.startsWith(`/articles/${articleId}/steps`)
        ? 'border border-orange-600 bg-white text-orange-600'
        : 'text-gray-700 hover:bg-gray-200'
        }`}
    >
      <RouteIcon size={16} className="mr-2" />
      Steps
    </Link>
  </div>);
}

export default ArticleStepsToggle;
