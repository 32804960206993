import React, { FC, useEffect, useState } from "react";
import { PageContainer } from "../components/containers/PageContainer";
import Header from "../components/ui/header/Header";
import { MainContainer } from "../components/containers/MainContainer";
import { EqualIcon, PlusIcon } from "lucide-react";
import { useQuery } from "react-query";
import { getUserProfile } from "../lib/profileService";
import { useAuth } from "../components/containers/AuthContext";
import { getHelpArticleUsage } from "../lib/helpArticlesService";


export const Usage = () => {
  // const { data: userProfile, isError: isUserProfileError, isLoading: isUserProfileLoading } = useQuery("userProfile", getUserProfile, {
  //   initialData: null,
  // });

  // const [regularArticles, setRegularArticles] = useState(50);
  // const [additionalArticles, setAdditionalArticles] = useState(25);
  // const [usedArticles, setUsedArticles] = useState(60);

  // const totalArticles = regularArticles + additionalArticles;
  // const usagePercentage = (usedArticles / totalArticles) * 100;
  // const regularPercentage = (regularArticles / totalArticles) * 100;
  // const additionalPercentage = (additionalArticles / totalArticles) * 100;

  const { data: usage, isError: isUsageError, isLoading: isUsageLoading } = useQuery("usage", getHelpArticleUsage, {
    initialData: null,
  });

  return (
    <PageContainer>
      <Header
        title="Usage"
      />

      <MainContainer>
        <div className="flex flex-col flex-1 gap-4">

          <div className="flex flex-col rounded-2xl overflow-hidden bg-white border border-gray-200 p-4 gap-2">
            <div className="flex flex-row justify-between">
              <h2 className="text-base font-semibold">Article Usage (monthly)</h2>
              <span className="text-sm opacity-60">{usage?.usage.last30Days} / {usage?.monthlyLimit}</span>
            </div>
            <div className="flex flex-col gap-2">
              <div className="h-2 w-full bg-zinc-200 rounded-full overflow-hidden relative">
                <div
                  className="h-full bg-orange-500"
                  style={{ width: `${(usage?.usage.last30Days || 0) / (usage?.monthlyLimit || 1) * 100}%` }}
                />
                
              </div>
            </div>
          </div>

          {/* <div className="flex flex-col rounded-2xl overflow-hidden bg-white border border-gray-200 p-4">
            <div className="flex flex-col gap-2 items-start">
              <h2 className="text-base font-semibold">Monthly articles</h2>
              <div className="flex flex-row gap-2">
                <div className="flex flex-col gap-2 border border-zinc-200 rounded-lg p-3">
                    <span className="text-sm opacity-60">HappySupport Pro</span>
                    <span className="text-xl font-semibold">{regularArticles} articles</span>
                </div>
                <div className="flex items-center justify-center">
                    <PlusIcon size={22} />
                </div>
                <div className="flex flex-col gap-2 border border-zinc-200 rounded-lg p-3">
                    <div className="flex flex-row gap-2">
                        <span className="flex items-center justify-center text-xs opacity-60 bg-zinc-200 rounded-md px-2 py-0 semi">2x</span>
                        <span className="text-sm opacity-60">Additional articles</span>
                    </div>
                    <span className="text-xl font-semibold">{additionalArticles} articles</span>
                </div>
                <div className="flex items-center justify-center">
                    <EqualIcon size={22} />
                </div>
                <div className="flex flex-col gap-2 border border-zinc-200 rounded-lg p-3">
                    <span className="text-sm opacity-60">Total</span>
                    <span className="text-xl font-semibold">{totalArticles} articles</span>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="flex flex-col rounded-2xl overflow-hidden bg-white border border-gray-200 p-4 gap-2">
            <div className="flex flex-row justify-between">
                <h2 className="text-base font-semibold">Article Usage (monthly)</h2>
                <span className="text-sm opacity-60">{usedArticles} / {totalArticles}</span>
            </div>
            <div className="flex flex-col gap-2">
                <div className="h-2 w-full bg-zinc-200 rounded-full overflow-hidden relative">
                    <div
                        className="h-full bg-orange-500"
                        style={{ width: `${usagePercentage}%` }}
                    />
                    <div
                        className="h-full bg-green-500 -mt-4"
                        style={{ width: `${regularPercentage}%` }}
                    />
                    <div
                        className="absolute top-0 bottom-0 w-0.5 bg-black"
                        style={{ left: `${regularPercentage}%` }}
                    />
                </div>
                <div className="flex justify-between text-xs text-gray-500 mt-1">
                    <div style={{ width: `${regularPercentage}%` }} className="flex flex-col border-x border-zinc-200">
                        <div className="h-[1px] w-full bg-zinc-200" />
                        <span className="text-xs opacity-80 text-center">Regular</span>
                    </div>
                    {additionalArticles > 0 && <div style={{ width: `${additionalPercentage}%` }} className="flex flex-col items-center border-r border-zinc-200">
                        <div className="h-[1px] w-full bg-zinc-200" />
                        <span className="text-xs opacity-80 text-center">Additional</span>
                    </div>}

                </div>
            </div>
          </div> */}
        </div>
      </MainContainer>
    </PageContainer>
  )
};
