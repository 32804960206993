import React, { useEffect, useState } from "react";
import { Button, SmallButton } from "../components/ui/Button";
import { Input } from "../components/ui/Input";

import { PageContainer } from "../components/containers/PageContainer";
import Header from "../components/ui/header/Header";
import { MainContainer } from "../components/containers/MainContainer";
import { useMutation, useQuery } from "react-query";
import { getUserProfile } from "../lib/profileService";
import { createCheckoutSession } from "../lib/paymentService";
import { queryClient } from "../lib/queryClient";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { EmptyState } from "../components/ui/EmptyState";
import { CheckCircle2Icon, CheckCircleIcon, XCircleIcon } from "lucide-react";

export const PlansCheckout: React.FC = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  enum CheckoutState {
    SUCCESS = "success",
    FAILURE = "failure",
  }

  const [checkoutState, setCheckoutState] = useState<CheckoutState | null>();


  useEffect(() => {
    if (searchParams.get("success") == "true") {
      setCheckoutState(CheckoutState.SUCCESS);
    } else if (searchParams.get("success") == "false") {
      setCheckoutState(CheckoutState.FAILURE);
    }
  }, [searchParams]);


  return (
    <PageContainer>
      <Header
        title="Checkout"
        subtitle="You can see all the help articles you recorded here."
        headerRight={<div></div>}
        />

      <MainContainer>
        <div className="flex flex-col gap-4">

          <div className="flex flex-col flex-1 bg-white p-4 border rounded-2xl border-gray-200">            
            {checkoutState == CheckoutState.SUCCESS && <div>
              <EmptyState
                title="Success"
                subtitle="You have successfully subscribed to the plan."
                icon={CheckCircle2Icon}
                  button={{
                    text: "Go to plans",
                    onClick: () => navigate("/plans"),
                    loading: false,
                  }}
              />
            </div>}

            {checkoutState == CheckoutState.FAILURE && <EmptyState
              title="Failure"
              subtitle="You have failed to subscribe to the plan."
              icon={XCircleIcon}
              button={{
                text: "Go to plans",
                onClick: () => navigate("/plans"),
                loading: false,
              }}
            />}
          </div>


        </div>
      </MainContainer>
    </PageContainer>
  );
};