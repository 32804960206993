import React, { useEffect, useState } from "react";
import { AuthPage } from "../components/auth/AuthPage";
import { Link, Navigate } from "react-router-dom";
import { CTAButton } from "../components/ui/Button";
import { FormSpacer } from "../components/ui/FormSpacer";
import { Input } from "../components/ui/Input";
import { useAuth } from "../components/containers/AuthContext";
import { register } from '../lib/authService';
import { useMutation } from "react-query";

export const Register = () => {
  const { isAuthenticated, loginSuccess } = useAuth();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { mutate: registerMutation, isLoading } = useMutation(register, {});
  const runRegister = () => {
    registerMutation({ 
      firstName,
      lastName,
      email,
      password,
      loginSuccess,
    });
  };

  if (isAuthenticated) return <Navigate replace to={{ pathname: "/get-started" }} />
  return (
    <AuthPage>
      <header className="flex-1 flex flex-col gap-y-4 items-center">
        <img
            src="/logo_black.png"
            alt="Support as a Service"
            width={40}
            height={40}
        />

        <h1 className="text-2xl font-bold mb-4">Welcome back</h1>
      </header>

      <div className="my-4">
        <Input
          label="Firstname"
          type="text"
          placeholder="John"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          fullWidth={true}
        />
        <FormSpacer />
        <Input
          label="Lastname"
          type="text"
          placeholder="Doe"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          fullWidth={true}
        />
        <FormSpacer />
        <Input
          label="Email"
          type="email"
          placeholder="john.doe@example.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth={true}
        />
        <FormSpacer />
        <Input
          label="Password"
          type="password"
          placeholder="**********"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth={true}
        />
        <FormSpacer />
        <CTAButton text="Register" onClick={runRegister} fullWidth isLoading={isLoading}/>
      </div>

      <footer className="flex-1 flex flex-col gap-y-4 items-center">
        <Link to={"/login"}>
          <span className="text-orange-600 hover:underline">Already have an account?</span>
        </Link>
      </footer>
    </AuthPage>
  )
};