import API from './axios';

const ENDPOINT = '/payment';

export type CheckoutSession = {
  redirectUrl: string;
};

export type BillingPortalSession = {
  redirectUrl: string;
};

export type Price = {
  id: string;
  currency: string;
  tax: string;
  unit_amount: number;
};

export type PriceInformation = {
  monthlyPrice: Price;
  yearlyPrice: Price;
};

export const createCheckoutSession = async (priceId: string): Promise<CheckoutSession> =>
  (await API.post(`${ENDPOINT}/create-checkout-session`, { priceId })).data;

export const createBillingPortalSession = async (): Promise<BillingPortalSession> =>
  (await API.post(`${ENDPOINT}/create-billing-portal-session`)).data;

export const getPrices = async (): Promise<PriceInformation> =>
  (await API.get(`${ENDPOINT}/prices`)).data;
