import React, { FC, useEffect, useRef, useState } from "react";
import { PageContainer } from "../components/containers/PageContainer";
import Header from "../components/ui/header/Header";
import { MainContainer } from "../components/containers/MainContainer";
import { Wand2Icon, ChevronLeftCircleIcon, ChevronLeftIcon, ChevronRightIcon, DownloadCloudIcon, EyeIcon, CheckIcon, SaveIcon, Newspaper, RouteIcon, TrashIcon, FileCheck2Icon } from "lucide-react";
import { Button, SmallButton } from "../components/ui/Button";
import { getHelpArticle, getHelpArticles, HelpArticle, downloadHelpArticle, deleteHelpArticle, updateHelpArticle } from "../lib/helpArticlesService";
import { useMutation, useQuery } from "react-query";
import ReactMarkdown from 'react-markdown';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import HelpArticleEditor from "../components/ui/HelpArticleEditor";
import ArticleStepsToggle from "../components/ui/ArticleStepsToggle";
import { queryClient } from "../lib/queryClient";


export const HelpArticlesTranslations = () => {

  const navigate = useNavigate();
  const { articleId } = useParams();

  const { data: article, isError: isArticleError, isLoading: isArticleLoading } = useQuery({
    queryKey: ["helpArticles", articleId],
    queryFn: () => getHelpArticle(articleId as string),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  return (
    <PageContainer>
      <Header
        showBackButton
        backButtonUrl={`/articles`}
        title={""}
        headerRight={
          <ArticleStepsToggle articleId={articleId as string} />
        }
      />

      <MainContainer>
        <div className="flex flex-row gap-4">
          Coming soon
        </div>

      </MainContainer>
    </PageContainer>
  )
};
