import React, { FC, useEffect, useState } from "react";
import { PageContainer } from "../components/containers/PageContainer";
import Header from "../components/ui/header/Header";
import { MainContainer } from "../components/containers/MainContainer";
import { ArrowDownIcon, ArrowRightIcon, BetweenHorizontalStartIcon, ChevronLeftCircleIcon, ChevronLeftIcon, ChevronRightIcon, DownloadCloudIcon, EyeIcon, ImageIcon, Loader2Icon, LoaderIcon, MousePointerClickIcon } from "lucide-react";
import { Button, SmallButton } from "../components/ui/Button";
import { getHelpArticle, getHelpArticles, HelpArticle } from "../lib/helpArticlesService";
import { useQuery } from "react-query";
import { Link, Route, Routes, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Trash2Icon } from "lucide-react";
import { deleteHelpArticle } from "../lib/helpArticlesService";
import { useMutation, useQueryClient } from "react-query";
import { Input, MultilineInput } from "../components/ui/Input";
import ArticleStepsToggle from "../components/ui/ArticleStepsToggle";

export const HelpArticlesDetailsSteps = () => {

  const navigate = useNavigate();
  const { articleId, stepId } = useParams();

  const { data: article, isError: isArticleError, isLoading: isArticleLoading } = useQuery({
    queryKey: ["helpArticles", articleId],
    queryFn: () => getHelpArticle(articleId as string),
  });

  return (
    <PageContainer>
      <Header
        title={"Help Article Steps"}
        showBackButton
        backButtonUrl={`/articles`}
        headerRight={<ArticleStepsToggle 
          articleId={articleId as string}
          articleLoading={!article?.content}
        />}
      />

      <MainContainer disableScroll={true}>
        <div className="flex flex-col flex-1 h-full overflow-hidden">
          <div className="flex flex-col flex-1 rounded-2xl overflow-hidden bg-white border border-gray-200">

            <div className="flex flex-col flex-1 overflow-hidden bg-gray-100">
              {article && (
                <div className="flex flex-col flex-1 gap-2 overflow-hidden">
                  <div className="flex flex-row flex-1 overflow-hidden">
                    <div className="flex flex-col w-60 border-r px-4 py-3 border-zinc-300 bg-white overflow-x-hidden overflow-y-auto">
                      <h2 className="text-md font-semibold text-gray-800">Steps</h2>

                      <div className="flex flex-col gap-2 mt-2">
                        {article.steps?.map((step, index) => (
                          <Link to={`/articles/${article.id}/steps/${step.id}`} key={step.id} className={`flex flex-row gap-2 rounded-xl p-1 cursor-pointer border-2 ${stepId === step.id ? 'bg-orange-600 bg-opacity-10 border-orange-600' : ' border-transparent hover:border-gray-300'}`}>
                            <img src={step.imageRaw} alt={`Step ${index + 1}`} className="flex-1 object-contain rounded-xl border border-zinc-300" />
                          </Link>
                        ))}
                      </div>
                    </div>
                    <div className="flex flex-1 flex-grow flex-col overflow-hidden">
                      {article.steps?.map((step, index) => (
                        <div key={step.id} className={`flex flex-1 flex-grow justify-center m-2 items-center overflow-hidden ${stepId === step.id ? 'block' : 'hidden'}`}>
                          <div className="relative flex max-h-full max-w-full justify-center items-center overflow-hidden border border-zinc-300 rounded-xl">
                            <div className="absolute inset-0 pointer-events-none">
                              {stepId && article.steps && !article.steps.find(step => step.id === stepId)?.action.includes('CLICK') && (
                                <div
                                  className="absolute h-4 w-4 border-2 border-orange-600 rounded-full"
                                  style={{
                                    left: `${(article.steps.find(step => step.id === stepId)?.positionX || 0) / (article.steps.find(step => step.id === stepId)?.rawImageWidth || 1) * 100}%`,
                                    top: `${(article.steps.find(step => step.id === stepId)?.positionY || 0) / (article.steps.find(step => step.id === stepId)?.rawImageHeight || 1) * 100}%`,
                                    transform: 'translate(-50%, -50%)'
                                  }}
                                />
                              )}
                              {stepId && article.steps && article.steps.find(step => step.id === stepId)?.action.includes('CLICK') && (
                                <div
                                  className="absolute h-4 w-4 border-2 border-orange-600 rounded-full"
                                  style={{
                                    left: `${(article.steps.find(step => step.id === stepId)?.positionX || 0) / (article.steps.find(step => step.id === stepId)?.rawImageWidth || 1) * 100}%`,
                                    top: `${(article.steps.find(step => step.id === stepId)?.positionY || 0) / (article.steps.find(step => step.id === stepId)?.rawImageHeight || 1) * 100}%`,
                                    transform: 'translate(-50%, -50%)'
                                  }}
                                />
                              )}
                            </div>
                            <img
                              src={step.imageRaw}
                              alt={`Step ${index + 1}`}
                              className="object-contain overflow-hidden h-full w-full"
                            />
                          </div>
                        </div>
                      ))}

                      {stepId && <div className="flex justify-center gap-4 items-center py-1 border-t border-zinc-300 bg-white">
                        <SmallButton
                          onClick={() => {
                            const currentIndex = article.steps?.findIndex(step => step.id === stepId);
                            if (currentIndex !== undefined && currentIndex > 0) {
                              navigate(`/articles/${article.id}/steps/${article.steps![currentIndex - 1].id}`);
                            }
                          }}
                          icon={<ChevronLeftIcon size={20} />}
                        />
                        <span className="text-lg">Step {(article.steps?.findIndex(step => step.id === stepId) || 0) + 1} / {article.steps?.length}</span>

                        <SmallButton
                          onClick={() => {
                            const currentIndex = article.steps?.findIndex(step => step.id === stepId);
                            if (currentIndex !== undefined && currentIndex < article.steps!.length - 1) {
                              navigate(`/articles/${article.id}/steps/${article.steps![currentIndex + 1].id}`);
                            }
                          }}
                          icon={<ChevronRightIcon size={20} />}
                        />

                      </div>}

                      {!stepId && (<div className="flex flex-col flex-1 justify-center items-center">
                        <span className="text-base opacity-60">Select a step to see more details</span>
                      </div>)}
                    </div>
                    <div className="flex flex-col w-72 border-l px-4 py-3 border-zinc-300 bg-white overflow-x-hidden overflow-y-auto gap-4">

                      {stepId && (
                        <>
                          <div className="flex flex-col gap-2">
                            <h2 className="flex flex-row text-md font-semibold text-gray-800">
                              <span>Analysis</span>
                              <div className="flex-grow" />
                              <span className="text-sm font-normal bg-orange-600 text-white rounded-lg px-2 py-0.5">AI</span>
                            </h2>
                            {article.steps?.find(step => step.id === stepId)?.summary ?
                              (<div className="flex flex-row border border-zinc-200 rounded-md p-2">
                                <span>
                                  {article.steps?.find(step => step.id === stepId)?.summary || ''}
                                </span>
                              </div>)
                              : (<div className="flex flex-row items-center justify-start gap-3 border border-zinc-300 rounded-md p-4">
                                <Loader2Icon className="animate-spin text-orange-600" />
                                <span className="text-base text-black opacity-80 font-medium">Analyzing step...</span>
                              </div>)}
                          </div>

                          <div className="flex flex-col gap-2">
                          <h2 className="flex flex-row text-md font-semibold text-gray-800">
                              <span>Details</span>
                            </h2>
                            <div className="flex flex-col">
                              <span><ImageIcon size={16} className="inline-block mr-2" />Size: {article.steps?.find(step => step.id === stepId)?.rawImageWidth} x {article.steps?.find(step => step.id === stepId)?.rawImageHeight}</span>
                              <span><ArrowRightIcon size={16} className="inline-block mr-2" />Start: {article.steps?.find(step => step.id === stepId)?.positionX}/{article.steps?.find(step => step.id === stepId)?.positionY}</span>
                              <span><ArrowDownIcon size={16} className="inline-block mr-2" />End: {article.steps?.find(step => step.id === stepId)?.endPositionX}/{article.steps?.find(step => step.id === stepId)?.endPositionY}</span>
                              <span><MousePointerClickIcon size={16} className="inline-block mr-2" />Type: {article.steps?.find(step => step.id === stepId)?.action}</span>

                            </div>
                          </div>

                          {/* <div className="flex flex-col gap-2">
                            <h2 className="text-md font-semibold text-gray-800">Comments</h2>
                            <MultilineInput
                              placeholder="Add more information about this step (optional)"
                              value={article.steps?.find(step => step.id === stepId)?.comment || ''}
                              onChange={(e) => {
                                const currentIndex = article.steps?.findIndex(step => step.id === stepId);
                                if (currentIndex !== undefined) {
                                  article.steps![currentIndex].comment = e.target.value;
                                }
                              }}
                            />
                          </div> */}

                        </>)}

                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </MainContainer>
    </PageContainer>
  )
};
