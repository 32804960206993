import React, { useEffect, useState } from "react";
import { Button } from "../components/ui/Button";
import { Input } from "../components/ui/Input";

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import { BookOpenIcon, CheckSquareIcon, ClockIcon, DollarSignIcon, EuroIcon, FilmIcon, HourglassIcon, RefreshCwIcon, UsersIcon, InfoIcon, PlusCircleIcon } from "lucide-react";
import { PageContainer } from "../components/containers/PageContainer";
import Header from "../components/ui/header/Header";
import { MainContainer } from "../components/containers/MainContainer";
import { useQuery } from "react-query";
import paddle from "../lib/payment/paddle";
import { getUserProfile } from "../lib/profileService";
import { CardElement } from '@stripe/react-stripe-js';

export const PlansCheckout: React.FC = () => {

  const { data: userProfile, isError: isUserProfileError, isLoading: isUserProfileLoading } = useQuery("userProfile", getUserProfile, {
    initialData: null,
  });

  const init = async () => {
    if (userProfile) {

    }
  }

  useEffect(() => {
    init();
  }, [userProfile]);

  return (
    <PageContainer>
      <Header
        title="Checkout"
        subtitle="You can see all the help articles you recorded here."
        headerRight={<div></div>}
      />

      <MainContainer>
        <div className="flex flex-col gap-4">

          <div className="flex flex-col flex-1 bg-white p-4 border rounded-2xl border-gray-200">
            <CardElement />
          </div>


        </div>
      </MainContainer>
    </PageContainer>
  );
};

export default PlansCheckout;