import { FC } from "react";

type PageContainerProps = {
  children?: React.ReactNode;
};

export const PageContainer: FC<PageContainerProps> = ({ children }) => {
  return (<div className="flex flex-1 flex-col overflow-hidden">
    <div className="flex flex-row items-center bg-orange-600 px-6 py-3">
      <span className="text-base text-white">You're currently using the tryout version with <span className="font-semibold underline">3 articles left</span>. Upgrade to a paid plan to unlock more features.</span>
    </div>
    {children}
  </div>)
};