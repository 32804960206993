import React from 'react';
import { LucideIcon } from 'lucide-react';
import { Button, SmallButton } from './Button';

interface EmptyStateProps {
  title: string;
  subtitle: string;
  icon: LucideIcon;
  button?: {
    text: string;
    onClick: () => void;
    loading?: boolean;
  };
}

export const EmptyState: React.FC<EmptyStateProps> = ({ title, subtitle, icon: Icon, button }) => {
  return (
    <div className="flex flex-col items-center justify-center h-full text-center px-4 py-6">
      <Icon className="w-16 h-16 text-gray-400 mb-4" />
      <h3 className="text-xl font-semibold text-back mb-2">{title}</h3>
      <p className="text-base opacity-60 mb-4">{subtitle}</p>
      {button && (
        <SmallButton onClick={button.onClick} text={button.text} isLoading={button.loading} />
      )}
    </div>
  );
};
